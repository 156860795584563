import React from "react";
import PropTypes from "prop-types";
import config from "../../../content/meta/config";

import { FaGithub } from "react-icons/fa/";
import { FaTwitter } from "react-icons/fa/";
import { FaLinkedinIn } from "react-icons/fa/";
import { FaStackOverflow } from "react-icons/fa/";
import { FaKeybase } from "react-icons/fa/"
import { FaMediumM } from "react-icons/fa/";
import { FaKey } from "react-icons/fa/";
import { FaRss } from "react-icons/fa/";

const iconMap = {
  twitter: FaTwitter,
  github: FaGithub,
  linkedin: FaLinkedinIn,
  stackoverflow: FaStackOverflow,
  medium: FaMediumM,
  keybase: FaKeybase,
  rss: FaRss
};

const SocialIcon = props => {
  const { theme, color, targetNetwork } = props;

  const targetUrl = config.authorSocialLinks.find(obj => {
    return obj.name === targetNetwork.toLowerCase();
  }).url;

  const Icon = iconMap[targetNetwork.toLowerCase()];

  return (
    <React.Fragment>
      <a href={targetUrl} aria-label={`${targetNetwork} social link`} target="_blank" rel="noopener noreferrer" className="thisIcon">
        <Icon />
      </a>

      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`
        .thisIcon {
          color: ${color};
          &:hover {
            -o-transition: color 0.15s ease-in, background 0.15s ease-in, border-color 0.15s ease-in;
            -ms-transition: color 0.15s ease-in, background 0.15s ease-in,
              border-color 0.15s ease-in;
            -moz-transition: color 0.15s ease-in, background 0.15s ease-in,
              border-color 0.15s ease-in;
            -webkit-transition: color 0.15s ease-in, background 0.15s ease-in,
              border-color 0.15s ease-in;
            transition: color 0.15s ease-in, background 0.15s ease-in, border-color 0.15s ease-in;
            color: ${theme.colour.brands[targetNetwork.toLowerCase()]};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

SocialIcon.propTypes = {
  theme: PropTypes.object.isRequired,
  targetNetwork: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default SocialIcon;
