import React from "react";
import PropTypes from "prop-types";
import Text from "./Text";
import SocialIcons from "./SocialIcons";

import { FaChevronDown } from "react-icons/fa/";
import Drawer from "../Drawer";

class Hero extends React.Component {
  constructor(props) {
    super(props)

  }

  state = {
    supportsWebp: true
  }

  componentDidMount() {
    let supportsWebp;
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      supportsWebp = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    } else {
      // very old browser like IE 8, canvas not supported
      supportsWebp = false;
    }
    this.setState({ supportsWebp: supportsWebp }); // cl
    console.log(supportsWebp)
  }

  render() {
    const {
      scrollToContent,
      backgrounds,
      theme,
      logo,
      title,
      subtitle,
      titleLink,
      displaySocial,
      tags,
      isTag,
      disableScroll
    } = this.props;

    let hasBackgrounds = true;
    let heroShadeClass = "hero-shade";
    let backgroundsToUse = backgrounds;
    let textColor = theme.colour.white;
    if (!backgrounds || !backgrounds.mobile || !backgrounds.desktop || !backgrounds.tablet) {
      heroShadeClass = "hero-shade-no-img";
      hasBackgrounds = false;
      backgroundsToUse = {};
      textColor = theme.colour.black;
    }
    const classes = `hero-bg ${this.state.supportsWebp ? 'supports-webp' : 'no-webp'}`

    return (
      <React.Fragment>
        {hasBackgrounds ? <section className={classes}/> : null}
        <section className={heroShadeClass}>
          <Text
            logo={logo}
            title={title}
            subtitle={subtitle}
            theme={theme}
            titleLink={titleLink}
            color={textColor}
            tags={tags}
            isTag={isTag}
          />
          {displaySocial ? <SocialIcons theme={theme}/> : null}
          {hasBackgrounds && !isTag && !disableScroll ? (
            <button className="scrollBtn" onClick={scrollToContent} aria-label="scroll">
              <FaChevronDown/>
            </button>
          ) : null}
        </section>

        {/* --- STYLES --- */}
        {/*language=SCSS*/}
        <style jsx>{`
          .hero-bg {
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: ${isTag ? "65vh" : "100vh"};
            opacity: 0.3;
            background-position: center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -ms-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            z-index: 0;


            &.supports-webp {
              background: ${theme.hero.desktopWebP};
              background-image: url(${backgroundsToUse.mobileWebP || ""});
            }

            &.no-webp {
              background: ${theme.hero.desktop};
              background-image: url(${backgroundsToUse.mobile || ""});
            }
          }

          .hero-shade {
            align-items: center;
            background: #080808;
            background-size: cover;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            min-height: ${isTag ? "65vh" : "100vh"};
            height: ${isTag ? "65vh" : "100%"};
            padding-top: 0;
            z-index: 10;
            padding-bottom: 0;
          }

          .hero-shade-no-img {
            align-items: center;
            display: flex;
            flex-flow: column nowrap;
            padding: 80px 0;
            justify-content: center;
            margin-top: 90px;
            z-index: 10;
            padding-bottom: 0;
          }

          button {
            background: none;
            border: 0;
            border-radius: 50%;
            font-size: ${theme.font.size.m};
            padding: ${theme.space.s} ${theme.space.m};
            cursor: pointer;
            z-index: 10;
            position: absolute;
            top: 93vh;

            &:focus {
              outline-style: none;
              background: none;
            }

            :global(svg) {
              fill: ${theme.colour.grey.light};
              animation-duration: ${theme.time.duration.long};
              animation-name: buttonIconMove;
              animation-iteration-count: infinite;
            }
          }

          @keyframes buttonIconMove {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
            100% {
              transform: translateY(0);
            }
          }

          @from-width tablet {
            .hero-bg {
              & .supports-webp {
                background-image: url(${backgroundsToUse.tabletWebP || ""});
              }

              &.no-webp {
                background-image: url(${backgroundsToUse.tablet || ""});
              }
            }

            .hero-bg
            h1 {
              max-width: 90%;
              font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
            }

            button {
              font-size: ${theme.font.size.l};
            }
          }

          @from-width desktop {
            .hero-bg {
              &.supports-webp {
                background-image: url(${backgroundsToUse.desktopWebP});
              }

              &.no-webp {
                background-image: url(${backgroundsToUse.desktop});
              }
            }

            h1 {
              max-width: 80%;
              font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};
            }

            button {
              font-size: ${theme.font.size.l};
            }
          }
        `}</style>
      </React.Fragment>
    )

  }

}
Hero.propTypes = {
  scrollToContent: PropTypes.func.isRequired,
  backgrounds: PropTypes.object,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  titleLink: PropTypes.string,
  displaySocial: PropTypes.bool,
  logo: PropTypes.string,
  tags: PropTypes.array,
  isTag: PropTypes.bool,
  disableScroll: PropTypes.bool
};

export default Hero;
