import React from "react";
import PropTypes from "prop-types";
import config from "../../../content/meta/config";
import SocialIcon from "../SocialIcon/index";

const SocialIcons = props => {
  const { theme } = props;

  const enabledFooterSocialLinks = config.enabledHeroSocialLinks;
  return (
    <React.Fragment>
      <section className="socialIcons">
        <ul className="list">
          {enabledFooterSocialLinks.map(item => {
            return (
              <li key={item}>
                <div className="list-item">
                  <SocialIcon
                    theme={theme}
                    targetNetwork={item}
                    color={theme.colour.grey.lightest}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </section>

      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`
        .socialIcons {
          z-index: 1;

          .list {
            z-index: 1;
            overflow: hidden;
            list-style: none;

            li {
              display: block;
              float: left;
              padding: 0 0.675em;
              font-size: 1.35em;
            }
          }
        }

        @from-width tablet {
        }

        @from-width desktop {
        }
      `}</style>
    </React.Fragment>
  );
};

SocialIcons.propTypes = {
  theme: PropTypes.object.isRequired
};

export default SocialIcons;
